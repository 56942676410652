@media only screen and (max-width: 991px) {
  /* -----------------------------------------------------
/ advertisement section style
/ --------------------------------------------------- */
  .advertisement_selection_area {
    background-color: #00add9;
    width: 100%;
    height: 83px;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.05);
  }

  .advertisement_selection {
    width: 100%;
    height: 51px;
    margin-top: 2px;
    border-radius: 5px;
    background-color: #ffffff;
  }

  /* Style for "SCB Bank's" header */
  .information_advertisement h2 {
    color: #545454;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.18px;
    line-height: 25px;
  }

  /* Style for "INTEREST h5" */
  .information_advertisement h5 {
    color: #6db867;
    font-size: 16px;

    line-height: 25px;
  }

  .information_advertisement p {
    /* Text style for "INTEREST P" */
    color: #848484;
    font-size: 13px;
  }

  .icon_fix6 {
    width: 44px;
    height: 44px;
  }

  .information_advertisement button {
    width: 328px;
    height: 54px;
    border-radius: 4px;
    background-color: #f4ca1f;
  }

  .border-top {
    border-top: 1px solid #dee2e6 !important;
  }

  .btn1 {
    color: #3b3b3b;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.16px;
    text-transform: uppercas;
  }

  /* -----------------------------------------------------
/ feature section style
/ --------------------------------------------------- */

  .feature_section {
    width: 100%;
    background-color: #44505b;
  }

  .feature_section h2 {
    color: #d9d9d9;
    padding-top: 10px;
    padding-bottom: 2px;
    font-size: 18px;
    margin-bottom: 0px;
    font-weight: 700;
    letter-spacing: 0.18px;
    line-height: 25px;
  }

  .single_feature {
    padding-top: 15px;
  }

  .feature_section p {
    color: #d9d9d9;
    padding-bottom: 10px;
    margin-top: -5px;
    margin-bottom: 0px;
    font-size: 16px;

    line-height: 25px;
  }

  .feature_bdr-b {
    border-bottom: 1px dashed #505050;
  }

  .color-yellow {
    color: #f4ca1f;
  }

  /* -----------------------------------------------------
/ card information section style
/ --------------------------------------------------- */
  .card_information {
    padding-bottom: 2px;
    width: 100%;
    background-color: #46586b;
  }

  .card_information h2 {
    color: #d9d9d9;
    padding-top: 10px;
    padding-bottom: 5px;
    margin-bottom: 0px;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.18px;
    line-height: 25px;
  }

  .single_card {
    padding: 10px 0px;
  }

  .single_card span {
    font-size: 16px;
  }

  .single_card p {
    color: #d9d9d9;
    margin-bottom: 0px;
    font-size: 16px;

    line-height: 25px;
  }

  .card_bdr-b {
    border-bottom: 1px dashed #5e5e5e;
  }

  /* -----------------------------------------------------
/ eligibility section style
/ --------------------------------------------------- */
  .elegibility_section {
    width: 100%;
    background-color: #ffffff;
  }

  .elegibility_section h2 {
    color: #545454;
    padding-top: 10px;
    padding-bottom: 5px;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.18px;
    line-height: 25px;
  }

  .elegibility_group {
    height: 290px;
    padding-bottom: 7px;
    border-radius: 5px;
  }

  .elegibility_group h3 {
    margin: 0px;
    padding: 10px 10px 10px 15px;
    font-size: 18px;

    line-height: 25px;
    border-radius: 4px 4px 0 0;
  }

  .elegibility_group i {
    padding: 5px 5px 5px 10px;
  }

  .single_elegibility {
    padding-bottom: 10px;
  }

  .single_elegibility p {
    color: #6a6a6a;
    margin-bottom: 0px;
    font-size: 14px;

    line-height: 25px;
  }

  .elegibility_infop {
    color: #4a4a4a;
    padding-left: 8px;

    line-height: 25px;
  }

  .elegibility_salary {
    border: 1px solid #d8f1f1;
    background-color: #f9ffff;
  }

  .elegibility_Business {
    border: 1px solid #eaf2e7;
    background-color: #fcfff9;
  }

  .elegibility_Professional {
    border: 1px solid #f2eaee;
    background-color: #fffafb;
  }

  .elegibility_land {
    border: 1px solid #f3eee0;
    background-color: #fffef8;
  }

  .elegibility_salary h3 {
    color: #599292;
    border-radius: 4px 4px 0 0;
    background-color: #effafa;
  }

  .elegibility_Business h3 {
    color: #688655;
    border-radius: 4px 4px 0 0;
    background-color: #f4fbf1;
  }

  .elegibility_Professional h3 {
    color: #7c5d6d;
    border-radius: 4px 4px 0 0;
    background-color: #f2eaee;
  }

  .elegibility_land h3 {
    color: #957c36;
    border-radius: 4px 4px 0 0;
    background-color: #f3eee0;
  }

  /* -----------------------------------------------------
/ advertisement section2 style
/ --------------------------------------------------- */
  .advertisement-banner2 {
    margin-bottom: 13px;
  }

  .information_advertisement_section2 h2 {
    color: #545454;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.18px;
    line-height: 25px;
  }

  .single-card-features {
    padding-top: 15px;
    border-top: 1px dashed #dedede;
    width: 100%;
  }

  .single-card-features .card-icon {
    width: 44px;
    height: 44px;
    border-radius: 50%;
  }

  .single-card-features .card-icon i {
    font-size: 24px;
  }

  .single-ad-card-content {
  }

  .single-ad-card-content h6 {
    color: #848484;
    font-size: 13px;
    font-family: 'Roboto';

    text-transform: uppercase;
  }

  .single-ad-card-content p {
    font-family: 'Roboto';
    font-size: 16px;

    line-height: 25px;
  }

  .information_advertisement_section2 a {
    width: 100%;
    height: 54px;
    border-radius: 4px;
    background-color: #f4ca1f;
    transition: all 0.3s ease;
    box-shadow: 0px 1px 3px 1px grey;
    line-height: 38px;
  }

  .information_advertisement_section2 a:focus {
    box-shadow: none;
  }

  .tell-inner {
    height: 172px;
    border-radius: 5px;
    background-color: #208dd3;
  }

  .tell-inner p {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.39;
    color: #f1f3f5;
  }

  .tell-us-button {
    height: 50px;
    padding: 0px 40px;
    border-radius: 25px;
    border: 0px;
    background-color: #f3c91d;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.56;
    color: #3b3b3b;
  }

  .tell-us-button:focus {
    box-shadow: none;
    outline: 0;
  }

  /* -------------------------------------------------------------
/  Modal Style
/ ----------------------------------------------------------- */
  .modal-area {
  }

  .modal-area-content {
    border-radius: 10px;
  }

  .modal-area-dialog {
    margin: 0 15px;
  }

  .modal-area-dialog .modal-area-conten {
    border-radius: 8px !important;
  }

  .modal-header,
  .modal-header-title {
    display: block;
    margin-top: -7px;
    margin-bottom: -4px;
  }

  .modal-header {
    border-bottom: 0px;
  }

  .modal-header svg {
    padding-bottom: 10px;
  }

  .modal-header,
  .close span {
    float: right;
    font-size: 35px;
    font-weight: 300;
    padding: 0px 7px;
    margin-top: -3px;
  }

  .modal-body {
    margin: 0px 20px;
    padding-bottom: 30px;
  }

  .modal-body ul {
  }

  .modal-body ul li {
    color: #3e3c3c;
    padding-bottom: 7px;
    font-size: 16px;

    line-height: 30px;
  }

  .modal-body ul li p {
    color: #61a265;
    margin-top: -8px;
    margin-bottom: 0px;
    font-size: 12px;

    line-height: 30px;
  }

  .modal-footer {
    flex-direction: column;
    text-align: center;
    padding: 20px 30px;
    border-top: 0px;
    margin: 15px;
    border-radius: 5px;
    background-color: #f3f8ff;
  }

  .modal-footer span {
    margin-top: -50px;
    z-index: 5;
  }

  .modal-footer h4 {
    color: #53586d;
    padding-top: 5px;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
  }

  .modal-footer p {
    color: #53586d;
    margin-bottom: 0px;
    font-size: 14px;

    line-height: 25px;
  }

  .modal-footer p span {
    font-weight: 700;
  }

  /* Modal 6 Style */
  .creditcard-modal6 {
    height: 527px;
    border-radius: 10px;
    background-color: #f3f8ff;
  }

  .creditcard-modal6 h4 {
    color: #53586d;
    margin-bottom: 0px;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
  }

  .creditcard-modal6 p {
    color: #53586d;
    font-size: 12px;
    font-weight: 300;
    line-height: 26px;
  }

  .creditcard-modal6 a {
    color: #ffffff !important;
    padding: 22px 30px;
    margin-top: 10px;
    font-size: 16px;
    height: 56px;
    border-radius: 5px;
    background-color: #53586d;
    font-weight: 500;
    line-height: 11px;
  }

  .creditcard-modal6 a:focus {
    box-shadow: none;
  }

  /* ------------------------------------------------------------
/  CreditCard Modal Table 5  Style
/ ---------------------------------------------------------- */

  .no-border {
    border: 0px;
  }

  .separator-border {
    width: 1px;
    height: 76px;
    margin-left: 6px;
    background-color: #e1e1e1;
  }

  .creditcard-right-img {
    margin-right: -3px;
  }

  .creditcard-modal5 {
    background-color: transparent;
    height: 527px;
    padding-bottom: 15px;
    border-radius: 10px;
    background-color: #ffffff;
  }

  .creditcard-modal5 .modal-header {
    display: flex;
    justify-content: space-between;
    padding: 0px 25px;
    margin-top: -14px;
    margin-bottom: 14px;
  }

  .creditcard-modal5 .modal-body {
    padding: 0px;
    margin: 0px 25px 0px 25px;
    overflow: auto;
    height: 100%;
  }

  .creditcard-modal5 .close span {
    float: right;
    font-size: 35px;
    font-weight: 300;
    padding: 0px 5px;
    margin-top: -5px;
  }

  .creditcard-table-chart {
  }

  .creditcard-table-chart table {
    margin-bottom: 0px;
  }

  .creditcard-table-chart table thead {
    height: 40px;
    width: 280px;
    background-color: #76a1c2;
    border: 1px solid #76a1c2;
  }

  .creditcard-table-chart table thead tr th {
    color: #ffffff;
    padding: 5px;
    font-size: 14px;
    font-weight: 300;
    line-height: 30px;
    text-transform: uppercase;
  }

  .creditcard-table-chart table tbody tr td {
    color: #9b9b9b;
    padding: 0px;
    font-size: 14px;

    line-height: 30px;
  }

  .table-bordered td,
  .table-bordered th {
    border: 0px;
  }

  /* ------------------------------------------------------------
/  Credit Card Modal Style
/ ---------------------------------------------------------- */
  .creditcard-modal-bg {
    height: 547px;
    border-radius: 10px;
    background-color: #f3f8ff;
    align-self: center;
  }

  .creditcard-modal {
    height: 527px;
    border-radius: 10px;
    background-color: #f3f8ff;
  }

  .creditcard-modal img {
  }

  .creditcard-modal h4 {
    color: #53586d;
    padding: 20px 0px 5px 0px;
    font-size: 20px;

    line-height: 30px;
  }

  .creditcard-modal {
    color: #919191;
    font-size: 14px;

    line-height: 25px;
  }

  .margin0auto {
    margin: 0px auto;
  }

  /* -------------------------------------------------------------
/ Support Modal Style
/ ----------------------------------------------------------- */
  .support-body-text h4 {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0.2px;
    color: #606060;
  }

  .support-body-text p {
    font-size: 16px !important;
    margin-bottom: 0px;
    line-height: 1.19 !important;
    letter-spacing: 0.2px !important;
    color: #8d8d8d !important;
  }

  .support-btn-group {
    margin-top: -4px;
  }

  .support-button {
    font-size: 16px;
    width: calc(50% - 7px);
    line-height: 34px;
    letter-spacing: 0.2px;
    color: #ffffff;
    height: 50px;
    border-radius: 25px;
    border: 0px;
    transition: all 0.5s ease-in-out;
  }

  .support-button:hover {
    animation: apply-pulse 1s linear;
    animation-iteration-count: infinite;
    box-shadow: 0 0 15px #2cb17c, 0 0 25px #2cb17c;
    transform: scale(1.01);
  }

  .support-button:focus {
    box-shadow: none;
  }

  @-webkit-keyframes apply-pulse {
    0% {
      box-shadow: 0 0 15px #2cb17c, 0 0 25px #2cb17c;
      border: 1px solid #2cb17c;
    }
    50% {
      box-shadow: 0 0 10px #2cb17c, 0 0 25px #2cb17c;
      border: 1px solid #2cb17c;
    }
    100% {
      box-shadow: 0 0 15px #2cb17c, 0 0 30px #2cb17c;
      border: 1px solid #2cb17c;
    }
  }

  @keyframes apply-pulse {
    0% {
      box-shadow: 0 0 15px #2cb17c, 0 0 25px #2cb17c;
      border: 1px solid #2cb17c;
    }
    50% {
      box-shadow: 0 0 10px #2cb17c, 0 0 25px #2cb17c;
      border: 1px solid #2cb17c;
    }
    100% {
      box-shadow: 0 0 15px #2cb17c, 0 0 30px #2cb17c;
      border: 1px solid #2cb17c;
    }
  }

  .support-button1 {
    font-size: 16px;
    width: calc(50% - 7px);
    line-height: 34px;
    letter-spacing: 0.2px;
    color: #ffffff;
    height: 50px;
    border-radius: 25px;
    border: 0px;
    transition: all 0.5s ease-in-out;
  }

  .support-button1:hover {
    animation: lets-chat 1s linear;
    animation-iteration-count: infinite;
    background: #00add9;
    box-shadow: 0 0 15px #00add9, 0 0 20px #00add9;
    transform: scale(1.02);
  }

  .support-button1:focus {
    box-shadow: none;
  }

  @-webkit-keyframes lets-chat {
    0% {
      box-shadow: 0 0 15px #00add9, 0 0 20px #00add9;
      border: 1px solid #00add9;
    }
    50% {
      box-shadow: 0 0 10px #00add9, 0 0 25px #00add9;
      border: 1px solid #00add9;
    }
    100% {
      box-shadow: 0 0 15px #00add9, 0 0 30px #00add9;
      border: 1px solid #00add9;
    }
  }

  @keyframes lets-chat {
    0% {
      box-shadow: 0 0 15px #00add9, 0 0 20px #00add9;
      border: 1px solid #00add9;
    }
    50% {
      box-shadow: 0 0 10px #00add9, 0 0 25px #00add9;
      border: 1px solid #00add9;
    }
    100% {
      box-shadow: 0 0 15px #00add9, 0 0 30px #00add9;
      border: 1px solid #00add9;
    }
  }

  .support-button:focus {
    box-shadow: none;
    outline: 0;
  }

  .modal-backdrop {
    height: auto;
  }

  /* Responsive Support Modal */
  @media all and (min-width: 320px) and (max-width: 359.9px) {
    .support-button {
      height: 40px;
      float: left;
    }

    .support-button1 {
      height: 40px;
      float: right;
    }
  }

  @media all and (min-width: 360px) and (max-width: 410.9px) {
    .support-button {
      height: 48px;
      float: left;
    }

    .support-button1 {
      height: 48px;
      float: right;
    }
  }

  /* -------------------------------------------------------------
/  successfull Interface Style
/ ----------------------------------------------------------- */
  .successfull-header {
    height: 56px;
    background-color: #00a4ce;
  }

  .successfull-header a {
    color: #ffffff;
    font-size: 16px;

    line-height: 25px;
  }

  .successfull-schedule {
  }

  .successfull-schedule span {
    color: #f3f8ff;
    font-size: 8px;
    font-weight: 300;
    line-height: 10px;
    text-transform: uppercase;
  }

  .date span:nth-child(2) {
    font-weight: 500;
  }

  .date span:nth-child(3) {
    font-weight: 300;
  }

  .time {
    color: #f3f8ff !important;
    margin-top: -10px;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 40px !important;
  }

  .time span {
    color: #f3f8ff;
    font-size: 8px;

    line-height: 15px;
    text-transform: uppercase;
  }

  .successfull-application {
  }

  .single-catagory-application {
  }

  .schedule-button {
    color: #707070;
    font-size: 12px;
    margin-top: 10px;
    height: 37px;
    width: 114px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    border: 0px;
    background-color: #ffffff;
    font-weight: 500;
    line-height: 15px;
  }

  .application-area {
    padding-top: 15px;
    margin-bottom: -15px;
    position: relative;
  }

  .application-area::before {
    content: '';
    position: absolute;
    top: 15px;
    left: 36px;
    width: 1px;
    height: 600px;
    background: #d9d9d9;
    z-index: 1;
  }

  .single-application {
    margin-bottom: 10px;
  }

  .single-application .media svg {
    z-index: 5;
  }

  .single-application .media .media-body p {
    color: #606060;
    margin-bottom: 0px;
    font-size: 16px;
    line-height: 26px;
  }

  .single-application .media .media-body span {
    color: #878787;
    font-size: 14px;
    line-height: 26px;
  }

  .surface-area {
  }

  .single-surface {
    width: 100%;
    padding-top: 5px;
    box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.2);
  }

  .single-surface a:focus {
    text-decoration: none;
  }

  .single-surface .media i {
    color: #c1c1c1;
    width: 16px;
    padding-top: 15px;
    font-size: 25px;
    font-weight: 600;
  }

  .single-surface .media-body p {
    color: #515151;
    margin-bottom: 0px;
    font-size: 16px;
    line-height: 26px;
  }

  .single-surface .media-body span {
    color: #9b9b9b;
    font-size: 12px;
    line-height: 26px;
  }

  /* ------------------------------------------------------------
/  Successfull Interface Table 4
/ ---------------------------------------------------------- */
  .width-900 {
    width: 900px;
  }

  .successfull-tabs-area {
  }

  .successfull-tabs-inner {
    overflow: auto;
  }

  .overflow-hidden {
    overflow: hidden !important;
  }
  .successfull-tabs .nav-item {
    height: 48px;
  }

  .successfull-tabs .nav-item .nav-link {
    color: rgba(0, 0, 0, 0.6);
    margin-right: 22px;
    padding: 12px 15px;
    font-size: 14px;
    text-align: center;
    font-weight: 500 !important;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    /*border-bottom: 3px solid #ddd !important;*/
  }

  .successfull-tabs .nav-link {
    border: 0px !important;
  }

  .nav-tabs .nav-link:focus,
  .nav-tabs .nav-link:hover {
    border-color: transparent;
  }

  /* .successfull-tabs .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #00add9;
  margin-right: 22px;
  padding: 13px 15px 11px 15px;
  background-color: #fff;
  border: 0;
  border-bottom: 2px solid #00add9 !important;
} */
  .successfull-content nav ul {
    padding: 0px 30px;
  }

  .successfull-content nav ul li {
    list-style: none;
    position: relative;
  }

  .successfull-content nav ul li:before {
    content: '';
    position: absolute;
    top: 22px;
    left: -17px;
    height: 5px;
    width: 5px;
    background: #808080;
    border-radius: 50%;
  }

  .successfull-content nav ul li a {
    color: #808080;
    font-size: 14px;
    letter-spacing: 0.01px;
    line-height: 50px;
  }

  .successfull-content nav ul li a:focus {
    text-decoration: none;
  }

  .position-fixed {
    position: fixed !important;
  }

  /* -------------------------------------------------------------
/  Successfull Interface Table 5
/ ----------------------------------------------------------- */
  .successfull-content5 {
  }

  .successfull-content5 h3 {
    color: #6a6a6a;
    padding: 18px 15px 0px 15px;
    font-size: 16px;
    line-height: 26px;
  }

  .successfull-content5 nav ul {
    padding: 0px 15px;
  }

  .successfull-content5 nav ul li {
    padding-bottom: 25px;
  }

  .successfull-content5 nav ul li .media svg {
  }

  .successfull-content5 nav ul li .media input {
    overflow: hidden;
    position: absolute;
    left: 0px;
    top: 0px;
    height: 50px;
    width: 50px;
    opacity: 0;
  }

  .successfull-content5 nav ul li:before {
    content: '';
    display: none;
    list-style: none;
  }

  .upload-images {
    margin-bottom: 0px;
  }

  /* -------------------------------------------------------------
/  Sign Up Style
/ ----------------------------------------------------------- */
  .signup-area {
  }

  .signup-area h3 {
    color: #474747;
    font-size: 20px;
    margin-bottom: 2px;
    text-align: center;
    font-weight: 500;
    letter-spacing: 0.2px;
    line-height: 48px;
  }

  .signup-area-inner {
  }

  .form-group-signup input {
    color: #585858;
    height: 55px;
    font-size: 16px;
    letter-spacing: 0.16px;
    border: 0px;
    background: #fff;
  }

  .form-group-signup select {
    color: #585858;
    height: 55px;
    font-size: 16px;
    letter-spacing: 0.16px;
    background: #fff;
    border: 0px;
  }

  .form-group-signup input:focus {
    border: 1px solid #00add9;
  }

  .signup-button {
    height: 55px;
    font-size: 20px;
    margin-top: 4px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 0.2px;
    color: #ffffff;
    border-radius: 5px;
    border: 1px solid #00add9;
    background-color: #00add9;
  }

  .signin-form p {
    color: #8d8d8d;
    font-size: 16px;
    padding-top: 2px;
    text-align: center;
    line-height: 19px;
    letter-spacing: 0.16px;
  }

  .signin-form p a {
    color: #009cc4;
  }

  /* -------------------------------------------------------------
/ Menu Area Style
/ ----------------------------------------------------------- */
  .menu-area {
    width: 305px;
    box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.2);
  }

  .menu-area-nav {
  }

  .menu-area-inner {
    transition: all 0.3s linear;
  }

  .menu-tab-list {
    width: 50%;
    height: 55px;
    background: #009cc4;
  }

  .menu-tab-list1 {
    width: 50%;
    height: 55px;
    background: #009cc4;
  }

  .menu-tab-list a {
    color: #ffffff !important;
    height: 55px;
    font-size: 16px;
    font-weight: 500;
    line-height: 40px;
    border-radius: 0px !important;
    letter-spacing: 0.2px;
    text-align: center;
  }

  .menu-tab-list1 a {
    color: #ffffff !important;
    height: 55px;
    font-size: 16px;
    font-weight: 500;
    line-height: 40px;
    border-radius: 0px !important;
    letter-spacing: 0.2px;
    text-align: center;
  }

  .menu-tab-list .active {
    font-size: 16px;
    height: 55px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0.2px;
    text-align: left;
    color: #6d6d6d !important;
    border-radius: 0px;
    background: #fff !important;
  }

  .menu-tab-list1 .active {
    font-size: 16px;
    height: 55px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0.2px;
    text-align: center;
    color: #fff !important;
    border-radius: 0px;
    background: #44505b !important;
  }

  .menu-area-content {
    transition: all 0.3s linear;
  }
  .menu-area-content .single-media .media-body p {
    color: #6d6d6d;
    font-size: 14px;
    text-align: left;
    margin-bottom: 0px;
  }

  .loan-brdr-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    padding-top: 13px;
  }

  .about-user-profile {
  }

  .user-profile-top {
    padding-top: 20px;
  }

  .user-details {
    padding-top: 30px;
    padding-bottom: 35px;
    background: #fff !important;
    height: 76vh !important;
  }

  .user-name p {
    color: #6d6d6d;
    font-size: 16px;
    padding-top: 8px;
    margin-bottom: 0px;
    line-height: 18px;
    letter-spacing: 0.21px;
  }

  .single-catagory-user {
    padding-bottom: 20px;
  }

  .single-catagory-user a:focus {
    text-decoration: none;
  }

  .single-catagory-user p {
    font-size: 14px;
    line-height: 1.57;
    letter-spacing: 0.2px;
    text-align: left;
    color: #6d6d6d;
  }

  .single-catagory-user i {
    font-size: 22px;
    line-height: 20px;
    letter-spacing: 0.2px;
    text-align: left;
    color: #6d6d6d;
  }

  /* --------------------------------------------------------------
/ Humbarg Menu Style 
/ ------------------------------------------------------------- */
  button:focus {
    outline: 0px;
  }

  .home-header-area {
    height: 56px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  }

  .box-shadow-none {
    box-shadow: none !important;
  }

  .labelMenu {
    transition: all 0.4s ease-in-out;
    width: 40px;
    height: 56px;
    padding-left: 10px;
    display: inline-block;
    text-align: center;
    line-height: 55px;
    border-radius: 100px;
  }

  /* NAVGATION: */

  .menu h1 {
    background-color: #313a45;
    text-align: center;
    color: #ddd;
    padding: 10px;
  }

  .notification-count {
    height: 17px;
    width: 17px;
    border-radius: 50%;
    border: 1px solid #fff;
    background: #fe6b32;
    position: absolute;
    top: 18px;
    right: 15px;
  }

  .notification-count span {
    color: #fff;
    font-size: 8px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 15.5px;
    font-weight: 500;
  }

  /* -------------------------------------------------------------
/ Account Settings Style
/ ----------------------------------------------------------- */
  .account-setting {
  }

  .single-account {
    box-shadow: 0 1px 0px 0 rgba(0, 0, 0, 0.1);
  }

  .single-account a:focus {
    text-decoration: none;
  }

  .single-account i {
    color: #3e3c3c;
    font-size: 22px;
    font-weight: 500;
  }

  .single-account .media-body {
    padding: 2px;
  }

  .single-account .media-body p {
    color: #575757;
    font-size: 16px;
    margin-bottom: 0px;
    line-height: 30px;
    letter-spacing: 0.2px;
  }

  .single-account .media-body span {
    color: #acacac;
    font-size: 14px;
    line-height: 1.79;
    letter-spacing: 0.2px;
    text-align: left;
  }

  /* ------------------------------------------------------------
/  Profile Style
/ ---------------------------------------------------------- */
  .bg-00add9 {
    background: #00add9;
  }

  .profile-banner-inner p {
    color: #fff;
    font-size: 16px;
    margin-bottom: 0px;
    font-weight: 600;
    line-height: 30px;
  }

  .profile-banner-inner span {
    color: #fff;
    font-size: 16px;
    line-height: 27px;
    margin-bottom: 10px;
  }

  /* ------------------------------------------------------------
/  Successfull Interface 4 Style
/ ----------------------------------------------------------- */
  .p-relative {
    position: relative;
    height: 100vh;
  }

  .Document-catagory-list-area {
    position: absolute;
    left: 0px;
    bottom: 0px;
    right: 0px;
    z-index: 10;
  }

  .catagory-list-group {
    box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.3);
    padding-bottom: 5px;
  }

  .single-catagory-document:active {
    color: #00add9;
  }

  .single-catagory-document p {
    font-size: 14px;
    line-height: 1.25;
    letter-spacing: 0.3px;
    color: #979797;
  }

  .separator {
    width: 1px;
    height: 31px;
    background-color: #ebebeb;
  }

  .color-00add9 {
    color: #00add9 !important;
  }

  /* -------------------------------------------------------------
/ My Matches Style
/ ----------------------------------------------------------- */
  .mymatches-area {
    background-color: #f3f3f3;
  }

  .maximum-loan {
  }

  .loan-title {
    border-bottom: 1px solid #f2f2f2;
  }

  .loan-title h4 {
    color: #b16a6a;
    font-size: 14px;
    padding-top: 13px;
  }

  .loan-title h4 i {
    font-size: 17px;
  }

  .bank-description {
  }

  .bank-description h3 {
    color: #505050;
    background: #fff;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.19;
  }

  .bank-description p {
    font-size: 16px;
    margin-bottom: 0px;
    line-height: 28px;
    color: #6d6d6d;
  }

  .bank-description span {
    font-size: 16px;
    line-height: 36px;
    color: #6d6d6d;
  }

  .bank-terms {
    padding-top: 8px;
  }

  .bank-terms a {
    font-size: 14px;
    color: #00a4ce;
    text-transform: uppercase;
    display: flex;
    align-items: center;
  }

  .apply-button {
    height: 40px;
    color: #4e4e4e;
    background: #f4ca1f;
    border-radius: 25px;
    border: 0px;
    padding: 0px 25px;
    font-size: 14px;
    font-weight: 500;
    line-height: 23px;
    white-space: nowrap;
    transition: all 0.3s ease-in-out;
  }

  .apply-button:hover {
    animation: pulse 1s linear;
    animation-iteration-count: infinite;
    box-shadow: 0 0 15px #f4ca1f, 0 0 25px #f4ca1f;
    background: #f4ca1f;
    transform: scale(1.02);
  }

  .apply-button:focus {
    animation: pulse 1s linear;
    animation-iteration-count: infinite;
    box-shadow: none;
  }

  @-webkit-keyframes pulse {
    0% {
      box-shadow: 0 0 5px #f4ca1f, 0 0 15px #f4ca1f;
      transition: all 0.3s ease;
    }
    50% {
      box-shadow: 0 0 8px #f4ca1f, 0 0 20px #f4ca1f;
      transition: all 0.3s ease;
    }
    100% {
      box-shadow: 0 0 11px #f4ca1f, 0 0 25px #f4ca1f;
      transition: all 0.3s ease;
    }
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 5px #f4ca1f, 0 0 15px #f4ca1f;
      transition: all 0.3s ease;
    }
    50% {
      box-shadow: 0 0 8px #f4ca1f, 0 0 20px #f4ca1f;
      transition: all 0.3s ease;
    }
    100% {
      box-shadow: 0 0 11px #f4ca1f, 0 0 25px #f4ca1f;
      transition: all 0.3s ease;
    }
  }

  .downscroll-bar {
  }

  .downscroll-bar p {
    width: 38px;
    height: 1px;
    background-color: #cccccc;
    margin: 0px auto 3px auto;
  }

  .more-content-bar {
    margin-top: -5px;
    padding-bottom: 20px;
  }

  .more-content-bar a {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.21;
    color: #db694c;
  }

  .mymatches-tabs {
    width: 500px;
  }

  .mymatches-tabs .nav-item {
    height: 51px !important;
    overflow: hidden;
  }

  .mymatches-tabs .nav-link {
    border: 0px !important;
  }

  .nav-tabs .nav-link:focus,
  .nav-tabs .nav-link:hover {
    border-color: transparent;
  }
  /* -------------------------------------------------------------
/ My Matches3 Style
/ ----------------------------------------------------------- */
  .personalloan-button-group button:focus {
    border-radius: 5px;
    outline: 0px;
    box-shadow: 0px;
  }

  .personalloan-button1 {
    color: #a16914;
    height: 50px;
    padding: 0px 17px;
    border-radius: 5px;
    border: solid 1px #f3dcbb;
    font-size: 14px;
    line-height: 1.21;
    letter-spacing: 0.1px;
  }

  .personalloan-button2 {
    color: #3d7e54;
    height: 50px;
    padding: 0px 17px;
    border-radius: 5px;
    border: solid 1px #bbe4ca;
    font-size: 14px;
    line-height: 1.21;
    letter-spacing: 0.1px;
  }

  .profile-instruction {
    height: calc(100vh - 175px);
  }

  .profile-instruction h3 {
    color: #505050;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
  }

  .profile-instruction p {
    color: #8d8d8d;
    font-size: 14px;
    padding-left: 40px;
    padding-right: 40px;
    line-height: 30px;
    text-align: center;
  }

  .profile-instruction a {
    color: #ffffff;
    font-size: 14px;
    padding: 0px 45px;
    margin-top: 20px;
    font-weight: 500;
    line-height: 51px;
    height: 50px;
    border-radius: 25px;
    background-color: #2cb17c;
  }

  .bg-fff7eb {
    background: #fff7eb;
  }

  .bg-f2fef6 {
    background: #f2fef6;
  }

  .p-top13 {
    padding: 15px 0px 7px 0px !important;
    font-weight: 400 !important;
  }

  .border-b-2px {
    border-bottom: 2px solid #dee2e6;
  }

  .w-32 {
    width: 32.5%;
  }

  .credit-h3 {
    font-size: 20px;
    padding: 20px 0px 5px 0px;
    text-align: center;
    color: #494949;
    /*margin-top: -10px;*/
  }

  .successfull-tabs-rough .nav-tabs .nav-item.show .nav-link1,
  .nav-tabs .nav-link1.active {
    color: #10b2d4;
    margin-right: 22px;
    padding: 20px 0px 15px 0px !important;
    background-color: #fff;
    border: 0;
    border-color: #10b2d4 !important;
  }

  .successfull-tabs-rough .nav-tabs .nav-item.show .nav-link2,
  .nav-tabs .nav-link2.active {
    color: #008ecc;
    margin-right: 22px;
    padding: 15px 0px 7px 0px !important;
    background-color: #fff;
    border: 0;
    border-color: #008ecc !important;
  }

  .successfull-tabs-rough .nav-tabs .nav-item.show .nav-link3,
  .nav-tabs .nav-link3.active {
    color: #7eb06a;
    margin-right: 22px;
    padding: 15px 0px 7px 0px !important;
    background-color: #fff;
    border: 0;
    border-color: #7eb06a !important;
  }
  .successfull-tabs-rough .nav-tabs .nav-item.show .nav-link1,
  .nav-tabs .nav-link1.active p {
    color: #10b2d4;
  }
  .successfull-tabs-rough .nav-tabs .nav-item.show .nav-link2,
  .nav-tabs .nav-link2.active p {
    color: #008ecc;
  }
  .successfull-tabs-rough .nav-tabs .nav-item.show .nav-link3,
  .nav-tabs .nav-link3.active p {
    color: #7eb06a;
  }
  .single-loan-area {
    width: calc(50% - 5px);
    height: 165.73px;
    border-radius: 5px;
    background-color: #e4eef2;
    position: relative;
  }

  .single-loan-area h4 {
    font-size: 16px;
    font-weight: 400;
    padding-top: 18px;
    line-height: 1.19;
    color: #0f0909;
    margin-bottom: 0px;
    font-family: 'Exo', sans-serif;
  }

  .single-loan-area img {
    padding-top: 0px;
    /*height: calc(100% - 6px);*/
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    margin: auto;
  }

  .svg-margin-top3 {
    margin-top: 3px;
  }

  .single-loan-area2 img {
    padding-top: 20px !important;
    height: calc(100% - 20px);
  }

  .single-loan-area3 img {
    padding-top: 15px !important;
    height: calc(100% - 20px);
  }

  .single-loan-area4 img {
    padding-top: 24px !important;
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    margin: auto;
  }

  /*
/*/
  .bg-44505b-1 {
    background: #fff;
  }

  .bg-10b2d4 {
    background: #10b2d4;
  }

  .bg-008ecc {
    background: #008ecc;
  }

  .bg-7eb06a {
    background: #7eb06a;
  }

  .bg-F7F7F7 {
    background: #f7f7f7 !important;
  }
}

/* -------------------------------------------------------------
/ Responsive Home3 css Code
/ ------------------------------------------------------------ */
@media all and (min-width: 320px) and (max-width: 359.9px) {
  .credit-h3 {
    font-size: 20px;
    padding: 0px 0px 5px 0px;
    text-align: center;
    color: #494949;
    /*margin-top: -10px;*/
  }
  .single-loan-area50 {
    width: calc(50% - 5px) !important;
  }
  .width-900 {
    width: 900px;
  }
  .successfull-tabs {
    width: 388px;
  }

  .successfull-tabss {
    /*width: 390px;*/
    justify-content: flex-start !important;
  }

  .successfull-tabs .nav-item {
    overflow: hidden;
  }

  .successfull-tabs-rough .nav-tabs .nav-item.show .nav-link1,
  .nav-tabs .nav-link1.active {
    color: #10b2d4 !important;
    padding: 20px 0px 15px 0px !important;
    /*border-bottom: 3px solid #10b2d4 !important;*/
  }

  .successfull-tabs-rough .nav-tabs .nav-item.show .nav-link2,
  .nav-tabs .nav-link2.active {
    color: #008ecc !important;
    padding: 10px 0px 7px 0px !important;
    border-bottom: 3px solid #008ecc !important;
  }

  .successfull-tabs-rough .nav-tabs .nav-item.show .nav-link3,
  .nav-tabs .nav-link3.active {
    color: #7eb06a !important;
    padding: 10px 0px 7px 0px !important;
    border-bottom: 3px solid #7eb06a !important;
  }

  .single-loan-area {
    height: 140px;
    width: 120px;
    overflow: hidden;
  }

  .single-loan-area:not(:last-child) {
    margin-right: 15px;
  }

  .single-loan-area4 svg {
    padding-top: 24px !important;
    height: calc(100% - 37px) !important;
  }

  .single-loan-area2 svg {
    padding-top: 20px !important;
    height: calc(100% - 37px) !important;
  }

  .single-loan-area3 svg {
    padding-top: 15px !important;
    height: calc(100% - 37px) !important;
  }

  .single-loan-area svg {
    padding-top: 0px;
    height: calc(100% - 32px);
  }

  .p-top11 {
    padding: 10px 0px 7px 0px !important;
    font-weight: 400 !important;
  }

  .p-top12 {
    padding: 20px 0px 15px 0px;
    font-weight: 400 !important;
  }

  .p-top13 {
    padding: 10px 0px 7px 0px !important;
    font-weight: 400 !important;
  }

  .nav-link1 {
    font-size: 12px !important;
  }

  .nav-link1 img {
    height: 50px;
  }

  .nav-link2 {
    font-size: 12px !important;
  }

  .nav-link2 svg {
    height: 50px;
  }

  .nav-link3 {
    font-size: 12px !important;
  }

  .nav-link3 img {
    height: 50px;
  }

  .w-32 {
    width: 93px;
    margin-right: 5px;
  }

  .w-32:last-child {
    margin-right: 0px !important;
  }

  .margint-top19 {
    margin-top: 8px;
  }
}

@media all and (min-width: 360px) and (max-width: 410.9px) {
  .credit-h3 {
    font-size: 20px;
    padding: 20px 0px 10px 0px;
    text-align: center;
    color: #494949;
    /*margin-top: -10px;*/
  }

  .single-loan-area50 {
    width: calc(50% - 5px) !important;
  }

  .successfull-tabs {
    width: 444px;
  }

  .successfull-tabss {
    /*width: 450px;*/
    justify-content: flex-start !important;
  }

  .successfull-tabs-rough .nav-tabs .nav-item.show .nav-link1,
  .nav-tabs .nav-link1.active {
    color: #10b2d4 !important;
    padding: 20px 0px 5px 0px !important;
    /*border-bottom: 3px solid #10b2d4 !important;*/
  }

  .successfull-tabs-rough .nav-tabs .nav-item.show .nav-link2,
  .nav-tabs .nav-link2.active {
    color: #008ecc !important;
    padding: 10px 0px 7px 0px !important;
    border-bottom: 3px solid #008ecc !important;
  }

  .successfull-tabs-rough .nav-tabs .nav-item.show .nav-link3,
  .nav-tabs .nav-link3.active {
    color: #7eb06a !important;
    padding: 10px 0px 7px 0px !important;
    border-bottom: 3px solid #7eb06a !important;
  }

  .single-loan-area {
    height: 185px;
    width: 140px;
    overflow: hidden;
  }

  .single-loan-area:not(:last-child) {
    margin-right: 15px;
  }

  .single-loan-area4 svg {
    padding-top: 65px !important;
    height: calc(100% - 37px) !important;
  }

  .single-loan-area2 svg {
    padding-top: 55px !important;
    height: calc(100% - 37px) !important;
  }

  .single-loan-area3 svg {
    padding-top: 51px !important;
    height: calc(100% - 37px) !important;
  }

  .single-loan-area svg {
    height: calc(100% - 32px);
  }

  .p-top11 {
    padding: 10px 0px 7px 0px !important;
    font-weight: 400 !important;
  }

  .p-top12 {
    padding: 20px 0px 15px 0px;
    font-weight: 400 !important;
  }

  .p-top13 {
    padding: 10px 0px 7px 0px !important;
    font-weight: 400 !important;
  }

  .nav-link1 {
    font-size: 12px !important;
  }

  .nav-link1 img {
    height: 63px;
  }

  .nav-link2 {
    font-size: 12px !important;
  }

  .nav-link2 img {
    height: 63px;
  }

  .nav-link3 {
    font-size: 12px !important;
  }

  .nav-link3 img {
    height: 63px;
  }

  .w-32 {
    width: 106px;
    margin-right: 5px;
  }

  .w-32:last-child {
    margin-right: 0px !important;
  }

  .margint-top19 {
    margin-top: 8px;
  }
}

@media all and (min-width: 411px) and (max-width: 599.9px) {
  .single-loan-area50 {
    width: calc(50% - 5px) !important;
  }

  .successfull-tabss {
    /*width: 500px !important;*/
    justify-content: flex-start !important;
  }

  .successfull-tabs .nav-item {
    height: 140px !important;
    overflow: hidden;
    width: 110px;
  }

  .successfull-tabs-rough .nav-tabs .nav-item.show .nav-link1,
  .nav-tabs .nav-link1.active {
    color: #10b2d4 !important;
    padding: 35px 0px 10px 0px !important;
    /*border-bottom: 3px solid #10b2d4 !important;*/
  }

  .successfull-tabs-rough .nav-tabs .nav-item.show .nav-link2,
  .nav-tabs .nav-link2.active {
    color: #008ecc !important;
    padding: 35px 0px 10px 0px !important;
    border-bottom: 3px solid #008ecc !important;
  }

  .successfull-tabs-rough .nav-tabs .nav-item.show .nav-link3,
  .nav-tabs .nav-link3.active {
    color: #7eb06a !important;
    padding: 35px 0px 10px 0px !important;
    border-bottom: 3px solid #7eb06a !important;
  }

  .single-loan-area {
    height: 195px;
    width: 155px;
    overflow: hidden;
  }

  .single-loan-area:not(:last-child) {
    margin-right: 15px;
  }

  .single-loan-area4 svg {
    padding-top: 65px !important;
    height: calc(100% - 27px) !important;
  }

  .single-loan-area2 svg {
    padding-top: 55px !important;
    height: calc(100% - 27px) !important;
  }

  .single-loan-area3 svg {
    padding-top: 51px !important;
    height: calc(100% - 27px) !important;
  }

  .single-loan-area img {
    padding-top: 75px;
  }

  .p-top11 {
    padding: 35px 0px 10px 0px !important;
    font-weight: 400 !important;
  }

  .p-top12 {
    padding: 35px 0px 10px 0px !important;
    font-weight: 400 !important;
  }

  .p-top13 {
    padding: 35px 0px 10px 0px !important;
    font-weight: 400 !important;
  }

  .nav-link1 {
    font-size: 12px !important;
  }

  .nav-link1 img {
    height: 65px;
  }

  .nav-link2 {
    font-size: 12px !important;
  }

  .nav-link2 img {
    height: 65px;
  }

  .nav-link3 {
    font-size: 12px !important;
  }

  .nav-link3 img {
    height: 65px;
  }

  .w-32 {
    width: 21.5%;
    margin-right: 5px;
  }

  .home3-res-width570 {
    width: 512px;
  }

  .credit-h3 {
    padding: 20px 0px 15px 0px;
  }

  .w-32:last-child {
    margin-right: 0px !important;
  }

  .margint-top19 {
    margin-top: 8px;
  }
}

@media all and (min-width: 600px) and (max-width: 767px) {
  .single-loan-area50 {
    width: calc(50% - 5px) !important;
  }

  .width-900 {
    width: 1410px;
  }

  .successfull-tabs {
    width: 696px !important;
    justify-content: flex-start !important;
  }

  .successfull-tabs .nav-item {
    height: 205px !important;
    overflow: hidden;
  }

  .successfull-tabs-rough .nav-tabs .nav-item.show .nav-link1,
  .nav-tabs .nav-link1.active {
    color: #10b2d4 !important;
    padding: 35px 0px 10px 0px !important;
    /*border-bottom: 3px solid #10b2d4 !important;*/
  }

  .successfull-tabs-rough .nav-tabs .nav-item.show .nav-link2,
  .nav-tabs .nav-link2.active {
    color: #008ecc !important;
    padding: 35px 0px 10px 0px !important;
    border-bottom: 3px solid #008ecc !important;
  }

  .successfull-tabs-rough .nav-tabs .nav-item.show .nav-link3,
  .nav-tabs .nav-link3.active {
    color: #7eb06a !important;
    padding: 35px 0px 10px 0px !important;
    border-bottom: 3px solid #7eb06a !important;
  }
  .single-loan-area {
    height: 345px;
    width: 33%;
    overflow: hidden;
  }

  .single-loan-area h4 {
    font-size: 20px;
  }

  .single-loan-area:not(:last-child) {
    margin-right: 15px;
  }

  .single-loan-area4 svg {
    padding-top: 159px !important;
    height: calc(100% - 0px) !important;
  }

  .single-loan-area2 svg {
    padding-top: 90px !important;
    height: calc(100% - 0px) !important;
  }

  .single-loan-area3 svg {
    padding-top: 85px !important;
    height: calc(100% - 0px) !important;
  }

  .single-loan-area svg {
    padding-top: 105px;
    height: calc(100% - 0px);
    width: 145px;
  }

  .p-top11 {
    padding: 35px 0px 10px 0px !important;
    font-weight: 400 !important;
  }

  .p-top12 {
    padding: 35px 0px 10px 0px !important;
    font-weight: 400 !important;
  }

  .p-top13 {
    padding: 35px 0px 10px 0px !important;
    font-weight: 400 !important;
  }

  .nav-link1 {
    font-size: 18px !important;

    margin-right: 5px !important;
  }

  .nav-link1 img {
    height: 115px;
    width: 100px;
  }

  .nav-link2 {
    font-size: 18px !important;

    margin-right: 5px !important;
  }

  .nav-link2 svg {
    height: 115px;
    width: 100px;
  }

  .nav-link3 {
    font-size: 18px !important;

    margin-right: 5px !important;
  }

  .nav-link3 img {
    height: 115px;
    width: 100px;
  }

  .w-32 {
    width: 25%;
  }

  .credit-h3 {
    font-size: 22px;
    padding: 20px 0px 15px 0px;
  }

  .single-catagory-document p {
    font-size: 16px;
  }

  .w-32:last-child {
    margin-right: 0px !important;
  }

  .margint-top19 {
    margin-top: 19px;
  }
}

@media all and (min-width: 768px) and (max-width: 991px) {
  .single-loan-area50 {
    width: calc(50% - 5px) !important;
  }

  .successfull-tabs {
    width: 100%;
  }

  .width-900 {
    width: 1390px;
  }

  .successfull-tabss {
    width: 100% !important;
    justify-content: flex-start !important;
  }

  .successfull-tabs .nav-item {
    height: 190px !important;
    overflow: hidden;
  }

  .successfull-tabs .nav-item:not(:last-child) {
    margin-right: 4px !important;
  }

  .successfull-tabs-rough .nav-tabs .nav-item.show .nav-link1,
  .nav-tabs .nav-link1.active {
    color: #10b2d4 !important;
    padding: 35px 0px 10px 0px !important;
    /*border-bottom: 3px solid #10b2d4 !important;*/
  }

  .successfull-tabs-rough .nav-tabs .nav-item.show .nav-link2,
  .nav-tabs .nav-link2.active {
    color: #008ecc !important;
    padding: 35px 0px 10px 0px !important;
    border-bottom: 3px solid #008ecc !important;
  }

  .successfull-tabs-rough .nav-tabs .nav-item.show .nav-link3,
  .nav-tabs .nav-link3.active {
    color: #7eb06a !important;
    padding: 35px 0px 10px 0px !important;
    border-bottom: 3px solid #7eb06a !important;
  }

  .single-loan-area {
    height: 395px;
    width: 33%;
    overflow: hidden;
  }

  .single-loan-area:not(:last-child) {
    margin-right: 15px;
  }

  .single-loan-area4 svg {
    padding-top: 240px !important;
    height: calc(100% - 35px) !important;
  }

  .single-loan-area2 svg {
    padding-top: 55px !important;
    height: calc(100% - 32px) !important;
  }

  .single-loan-area3 svg {
    padding-top: 50px !important;
    height: calc(100% - 25px) !important;
  }

  .single-loan-area svg {
    padding-top: 155px;
    height: calc(100% - 0px);
    width: 145px;
  }

  .p-top11 {
    padding: 35px 0px 10px 0px !important;
    font-weight: 400 !important;
  }

  .p-top12 {
    padding: 35px 0px 10px 0px !important;
    font-weight: 400 !important;
  }

  .p-top13 {
    padding: 35px 0px 10px 0px !important;
    font-weight: 400 !important;
  }

  .nav-link1 {
    font-size: 18px !important;
  }

  .nav-link1 img {
    height: 105px;
    width: 100px;
  }

  .nav-link2 {
    font-size: 18px !important;
  }

  .nav-link2 svg {
    height: 105px;
    width: 100px;
  }

  .nav-link3 {
    font-size: 18px !important;
  }

  .nav-link3 img {
    height: 105px;
    width: 100px;
  }

  .w-32 {
    width: 24.5%;
  }

  .credit-h3 {
    font-size: 25px;
    padding: 28px 0px 17px 0px;
  }

  .single-loan-area h4 {
    font-size: 20px;
  }

  .single-catagory-document {
    margin: auto;
  }

  .single-catagory-document svg {
    height: 25px;
    width: 25px;
  }

  .single-catagory-document p {
    font-size: 16px;
  }

  .w-32:last-child {
    margin-right: 0px !important;
  }

  .margint-top19 {
    margin-top: 19px;
  }
}

/* ---------------------------------------------------------------------
/ Responsive Mymatches Area
/ -------------------------------------------------------------------- */
@media all and (min-width: 320px) and (max-width: 359.9px) {
  .mymatches-tabs {
    width: 500px;
  }

  .mymatches-tabs .nav-item {
    height: 51px !important;
    overflow: hidden;
  }
  .mymatches-tabs .nav-link {
    border: 0px !important;
  }

  .nav-tabs .nav-link:focus,
  .nav-tabs .nav-link:hover {
    border-color: transparent;
  }

  .personalloan-button1 {
    padding: 0px 10px;
    font-size: 13px;
  }

  .personalloan-button2 {
    padding: 0px 10px;
    font-size: 13px;
  }

  .bank-terms a {
    font-size: 14px;
    padding-left: 30px;
    text-align: center;
    line-height: 25px;
    margin-top: -5px;
  }

  .bank-terms a i {
    font-size: 22px;
    padding-left: 10px;
    margin-right: 0px;
    margin-top: 0px;
    font-weight: 600;
  }

  .apply-button {
    padding: 0px 25px;
    font-size: 13px;
  }
}

@media all and (min-width: 360px) and (max-width: 410px) {
  .mymatches-tabs {
    width: 500px;
  }

  .mymatches-tabs .nav-item {
    height: 51px !important;
    overflow: hidden;
  }

  .mymatches-tabs .nav-link {
    border: 0px !important;
  }

  .nav-tabs .nav-link:focus,
  .nav-tabs .nav-link:hover {
    border-color: transparent;
  }
  .personalloan-button1 {
    padding: 0px 15px;
    font-size: 14px;
  }

  .personalloan-button2 {
    padding: 0px 15px;
    font-size: 14px;
  }

  .bank-terms a {
    font-size: 14px;
    width: 125px;
    line-height: 27px;
    text-align: center;
    margin-top: -7px;
  }

  .bank-terms a i {
    font-size: 22px;
    padding-left: 10px;
    margin-right: 0px;
    margin-top: 0px;
    font-weight: 600;
  }

  .apply-button {
    padding: 0px 30px;
    font-size: 14px;
  }
}

@media all and (min-width: 411px) and (max-width: 599.9px) {
  .mymatches-tabs {
    width: 500px;
  }

  .mymatches-tabs .nav-item {
    height: 51px !important;
    overflow: hidden;
  }

  .mymatches-tabs .nav-link {
    border: 0px !important;
  }

  .nav-tabs .nav-link:focus,
  .nav-tabs .nav-link:hover {
    border-color: transparent;
  }
  .personalloan-button1 {
    padding: 0px 25px;
    font-size: 14px;
  }

  .personalloan-button2 {
    padding: 0px 25px;
    font-size: 14px;
  }

  .bank-terms a {
    font-size: 14px;
  }

  .bank-terms a i {
    font-size: 22px;
    padding-left: 10px;
    margin-right: 0px;
    margin-top: 0px;
    font-weight: 600;
  }

  .apply-button {
    padding: 0px 30px;
    font-size: 14px;
    height: 45px;
  }
}

@media all and (min-width: 600px) and (max-width: 991.9px) {
  .mymatches-tabs {
    width: 100%;
  }

  .mymatches-tabs .nav-item {
    height: 51px !important;
    overflow: hidden;
  }

  .mymatches-tabs .nav-link {
    border: 0px !important;
  }

  .nav-tabs .nav-link:focus,
  .nav-tabs .nav-link:hover {
    border-color: transparent;
  }

  .personalloan-button1 {
    padding: 0px 35px;
    font-size: 16px;
    width: 49%;
  }

  .personalloan-button2 {
    padding: 0px 35px;
    font-size: 16px;
    width: 49%;
  }

  .loan-title h4 {
    font-size: 16px;
  }

  .bank-description h3 {
    font-size: 18px;
  }

  .bank-terms a {
    font-size: 16px;
  }

  .bank-terms a i {
    font-size: 22px;
    padding-left: 10px;
    margin-right: 0px;
    margin-top: 0px;
    font-weight: 600;
  }

  .apply-button {
    padding: 0px 55px;
    font-size: 16px;
    height: 50px;
  }

  .profile-instruction a {
    font-size: 16px;
  }

  .more-content-bar a {
    font-size: 16px;
  }
}
